'use client';

import { IntlConfig, NextIntlClientProvider } from 'next-intl';
import { PropsWithChildren } from 'react';

import { config } from '@/app/config/intl';

type NextIntlProviderProps = PropsWithChildren & Pick<IntlConfig, 'locale' | 'now' | 'timeZone' | 'messages' | 'formats'>

export default function NextIntlProvider (props: NextIntlProviderProps) {
    const { children, ...others } = props;
    return (
        <NextIntlClientProvider
            {...others}
            {...config}
        >
            {children}
        </NextIntlClientProvider>
    );
}
