import { IntlConfig } from 'use-intl/core';

export const config: Omit<IntlConfig, 'locale'> = {
    defaultTranslationValues: {
        br: () => <br />,
        strong: chunks => <strong>{chunks}</strong>,
        b: chunks => <b>{chunks}</b>
    },
    formats: {},
    onError (error) {
        // eslint-disable-next-line no-console
        console.log(error.message);
    },
    getMessageFallback ({ key, namespace }) {
        return [namespace, key].filter(part => part != null).join('.');
    }
};
