'use client';

import { domMax, LazyMotion } from 'framer-motion';
import { PropsWithChildren } from 'react';

export default function MotionProvider (props: PropsWithChildren) {
    return (
        <LazyMotion features={domMax} strict>
            {props.children}
        </LazyMotion>
    );
}
