'use client';

import 'react-toastify/dist/ReactToastify.min.css';
import './index.scss';

import { IconMoodCheck, IconMoodCry, IconMoodEmpty, IconMoodHappy, IconSquareRoundedX } from '@tabler/icons-react';
import { Mutation } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import axios from 'axios';
import { useTranslations } from 'next-intl';
import { ReactNode } from 'react';
import { Slide, ToastContainer as ReactToastifyContainer, ToastOptions } from 'react-toastify';

type ToastContentPropsData = {
    title?: ReactNode
    description?: ReactNode
    _query?: {
        mutation: Mutation<unknown, unknown, unknown>,
        data: AxiosError | any
    }
} & Record<string, any>

type ToastContentProps = {
    closeToast?: () => void;
    toastProps: ToastOptions;
    data?: ToastContentPropsData;
}

function prepareErrorMessage (error: unknown | AxiosError | Error) {
    let errorMsg = '';
    if (!error) return errorMsg;

    try {
        if (axios.isAxiosError(error)) {
            const message = error.response?.data?.message;

            if (typeof message === 'string') {
                errorMsg = message;
            }
        } else {
            if (error instanceof Error && error.message) {
                errorMsg = error.message;
            }
        }
    } catch {}

    return errorMsg;
}



export default function BaseToast ({ closeToast, data, toastProps }: Partial<ToastContentProps>) {
    const t = useTranslations();
    const customTitle = data?.title;
    const customDescription = data?.description;

    const successMsg = data?._query?.data?.message;

    const errorMsg = prepareErrorMessage(data?._query?.data);

    const description = {
        error: errorMsg,
        success: typeof successMsg === 'string' ? successMsg : null,
        info: '',
        warning: '',
        default: toastProps?.type === 'error' ? t('common.defaultError') : t('common.defaultSuccess')
    };

    const icon = {
        success: <IconMoodHappy />,
        error: <IconMoodCry />,
        info: <IconMoodCheck />,
        warning: <IconMoodEmpty />,
        default: ''
    };

    return (
        <div className="base-toast__item">
            <div className="base-toast__item-icon">
                {icon[toastProps?.type || 'success']}
            </div>
            <div className="base-toast__item-text-content">
                {
                    customTitle && (
                        <p className="base-toast__item-title">
                            {customTitle}
                        </p>
                    )
                }
                <div className="base-toast__item-description">
                    {customDescription || description[toastProps?.type || 'default'] || description['default']}
                </div>
            </div>
            <div className="base-toast__item-actions">
                <button
                    className="base-toast__item-close-btn"
                    type="button"
                    onClick={closeToast}
                >
                    <IconSquareRoundedX size={25}/>
                </button>
            </div>
        </div>
    );
}


export function ToastContainer () {
    return (
        <ReactToastifyContainer
            limit={3}
            stacked
            icon={false}
            hideProgressBar
            closeOnClick={false}
            closeButton={false}
            className="base-toast__container"
            toastClassName="base-toast__item-wrapper"
            position="bottom-right"
            autoClose={5000}
            newestOnTop
            theme="light"
            transition={Slide}
        />
    );
}
