'use client';

import { AppProgressBar as ProgressBar } from 'next-nprogress-bar';

import { PRIMARY_COLOR } from '@/app/providers/mantine';

export default function TopLoader () {
    return (
        <ProgressBar
            color={PRIMARY_COLOR}
            height="3px"
            options={{ showSpinner: false }}
        />
    );
}