'use client';

import { Mutation, MutationCache, QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryStreamedHydration } from '@tanstack/react-query-next-experimental';
import { PropsWithChildren, useRef, useState } from 'react';
import { toast } from 'react-toastify';

import BaseToast from '@/app/shared-components/base-toast';
import { MutationAndQueryMeta, MutationData } from '@/app/types/shared';

type ShowToastArgs = {
    data: Error | unknown
    variables: unknown | MutationData
    mutation: Mutation<unknown, unknown, unknown>
    type: 'error' | 'success'
}

function showToast ({ data, variables, mutation, type }: ShowToastArgs) {
    const { _meta } = (variables || {}) as MutationData;
    if (!(_meta?.toast && type in _meta.toast)) return;

    toast[type](<BaseToast />, {
        ...(_meta.toast[type] || {}),

        data: {
            ...(_meta.toast[type]?.data || false),
            _query: {
                mutation,
                data
            }
        }
    });
}

export default function QueryProvider ({ children }: PropsWithChildren) {
    const metaData = useRef<Map<number, MutationAndQueryMeta>>(new Map());
    const [queryClient] = useState(() => new QueryClient({
        mutationCache: new MutationCache({
            onMutate: (variables, mutation) => {
                if (metaData.current.has(mutation.mutationId)) {
                    metaData.current.delete(mutation.mutationId);
                }

                if (typeof variables === 'object' && variables !== null && '_meta' in variables) {
                    metaData.current.set(mutation.mutationId, variables._meta as MutationAndQueryMeta);
                    delete variables._meta;
                }
            },
            onError: (data, variables, context, mutation) => {
                if (metaData.current.has(mutation.mutationId)) {
                    showToast({
                        data,
                        mutation,
                        variables: {
                            ...(variables || {}),
                            _meta: metaData.current.get(mutation.mutationId)
                        },
                        type: 'error'
                    });
                }
            },
            onSuccess: (data, variables, context, mutation) => {
                if (metaData.current.has(mutation.mutationId)) {
                    showToast({
                        data,
                        mutation,
                        variables: {
                            ...(variables || {}),
                            _meta: metaData.current.get(mutation.mutationId)
                        },
                        type: 'success'
                    });
                }
            },
            onSettled: (data, error, variables, context, mutation) => {
                if (metaData.current.has(mutation.mutationId)) {
                    metaData.current.delete(mutation.mutationId);
                }
            }
        }),
        defaultOptions: {
            queries: {
                staleTime: 60 * 1000,
                refetchOnWindowFocus: false,
                retry: (failureCount, error) =>
                    // @ts-ignore
                    error?.response?.status !== 401 && failureCount < 3

            }
        }
    }));

    return (
        <QueryClientProvider client={queryClient}>
            <ReactQueryStreamedHydration>
                {children}
            </ReactQueryStreamedHydration>
        </QueryClientProvider>
    );
}
