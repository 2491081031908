'use client';

import './index.scss';

import { useIsMutating } from '@tanstack/react-query';
import { AnimatePresence, m, Variants } from 'framer-motion';
import { memo, useState } from 'react';

import { MUTATION_KEYS } from '@/app/config/query';
import { useUserInfo } from '@/app/shared-composables/useQueries/user/me/get';

const wrapperVariants = {
    animate: {
        opacity: 1,
        filter: 'blur(0px)'
    },
    exit: {
        opacity: 0,
        zIndex: -1,
        filter: 'blur(12px)',
        transition: {
            ease: 'easeIn',
            duration: 0.5,
            zIndex: {
                delay: 0.5,
                duration: 0.5
            }
        }
    }
};


const itemVariants: Variants = {
    show: {
        opacity: 1,
        y: 0,
        transition: { type: "spring", stiffness: 300, damping: 24 }
    },
    hidden: { opacity: 0, y: -20, transition: { duration: 0.1 } }
};

export default memo(function AuthLoader () {
    const [isAnimationComplete, setIsAnimationComplete] = useState(false);
    const isSignInMutating = useIsMutating({ mutationKey: [MUTATION_KEYS.AUTH.SIGN_IN.POST] });
    const isSignOutMutating = useIsMutating({ mutationKey: [MUTATION_KEYS.AUTH.LOGOUT.POST] });
    const userQuery = useUserInfo();
    const isShow = isSignInMutating || isSignOutMutating || userQuery.isInitialLoading;

    return (
        <AnimatePresence mode="wait" initial={true}>
            <m.div
                className="auth-loader"
                variants={wrapperVariants}
                animate={isShow || !isAnimationComplete ? 'animate' : 'exit'}
            >
                <m.svg width="93" height="32" viewBox="0 0 93 32" fill="none" xmlns="http://www.w3.org/2000/svg"
                    variants={{
                        hidden: { y: -100, opacity: 0 },
                        show: {
                            y: 0,
                            opacity: 1,
                            transition: { duration: 1, staggerChildren: 0.1, delayChildren: 0.05 }
                        }
                    }}
                    onAnimationComplete={() => setIsAnimationComplete(true)}
                    initial="hidden"
                    animate="show"
                >
                    <m.path variants={itemVariants} d="M0 16.0748V0.112305H6.13771C7.45668 0.112305 8.61827 0.322141 9.62248 0.741813C10.6267 1.16148 11.466 1.73104 12.1405 2.45048C12.815 3.16992 13.3246 4.01675 13.6693 4.99099C14.0141 5.95024 14.1864 6.97694 14.1864 8.07109C14.1864 9.28514 13.9916 10.3868 13.6019 11.376C13.2272 12.3502 12.6876 13.1896 11.9831 13.894C11.2787 14.5835 10.4319 15.1231 9.44263 15.5128C8.4534 15.8875 7.35176 16.0748 6.13771 16.0748H0ZM9.7349 8.07109C9.7349 7.45657 9.65246 6.89451 9.48759 6.3849C9.32272 5.8753 9.08291 5.44064 8.76815 5.08092C8.46839 4.7212 8.09368 4.44392 7.64403 4.24907C7.19438 4.03924 6.69227 3.93432 6.13771 3.93432H4.38408V12.2528H6.13771C6.70726 12.2528 7.21686 12.1479 7.66651 11.9381C8.11616 11.7282 8.49087 11.436 8.79063 11.0613C9.10539 10.6865 9.33771 10.2444 9.48759 9.73479C9.65246 9.22519 9.7349 8.67062 9.7349 8.07109Z" fill="#007AFF"/>
                    <m.path variants={itemVariants} d="M66.0219 0.112305H70.0238L75.5545 16.0748H71.1029L70.1362 12.9498H65.887L64.9427 16.0748H60.4688L66.0219 0.112305ZM69.4392 10.0271L68.0228 5.08092L66.539 10.0271H69.4392Z" fill="#007AFF"/>
                    <m.path variants={itemVariants} d="M77.3494 16.0748V0.112305H81.7334V12.2528H88.9728V16.0748H77.3494Z" fill="#007AFF"/>
                    <m.path variants={itemVariants} d="M16.5618 16.0748V0.112305H20.9458V16.0748H16.5618Z" fill="#007AFF"/>
                    <m.path variants={itemVariants} d="M35.0021 14.7485C33.863 15.7077 32.5516 16.1874 31.0677 16.1874C30.0335 16.1874 29.0443 16.0075 28.1 15.6478C27.1558 15.2731 26.3314 14.7335 25.627 14.029C24.9225 13.3246 24.3605 12.4628 23.9408 11.4436C23.5211 10.4244 23.3113 9.26276 23.3113 7.95878C23.3113 6.83466 23.5211 5.79297 23.9408 4.83372C24.3605 3.85949 24.9375 3.02014 25.6719 2.31569C26.4064 1.59625 27.2682 1.03419 28.2574 0.629508C29.2616 0.209836 30.3408 0 31.4949 0C32.2143 0 32.9038 0.0824354 33.5633 0.247306C34.2228 0.39719 34.8298 0.629509 35.3843 0.944262C35.9539 1.24403 36.4635 1.61124 36.9132 2.0459C37.3628 2.46557 37.73 2.9452 38.0148 3.48478L34.7548 5.98033C34.4401 5.35082 33.968 4.84871 33.3384 4.474C32.7089 4.08431 32.042 3.88946 31.3375 3.88946C30.8129 3.88946 30.3333 3.99438 29.8986 4.20422C29.464 4.41405 29.0893 4.70632 28.7745 5.08103C28.4598 5.45574 28.2125 5.90539 28.0326 6.42998C27.8527 6.93958 27.7628 7.50164 27.7628 8.11616C27.7628 8.74567 27.8527 9.31522 28.0326 9.82482C28.2125 10.3344 28.4598 10.7766 28.7745 11.1513C29.1043 11.511 29.4939 11.7958 29.9436 12.0056C30.3932 12.2005 30.8954 12.2979 31.4499 12.2979C32.7239 12.2979 33.848 11.7733 34.8223 10.7241H32.0569V7.57658H38.6218V16.0749H35.0021V14.7485Z" fill="#007AFF"/>
                    <m.path variants={itemVariants} d="M41.2544 16.0748V0.112305H45.6385V16.0748H41.2544Z" fill="#007AFF"/>
                    <m.path variants={itemVariants} d="M61.2911 3.93432H56.7046V16.0748H52.3206V3.93432H47.7117V0.112305H61.2911V3.93432Z" fill="#007AFF"/>



                    <m.path variants={itemVariants} d="M0.719238 32.0001V16.0376H1.86584V30.9659H11.3984V32.0001H0.719238Z" fill="#242731"/>
                    <m.path variants={itemVariants} d="M14.2512 32.0001V16.0376H15.3978V32.0001H14.2512Z" fill="#242731"/>
                    <m.path variants={itemVariants} d="M31.813 27.8634C31.813 28.6428 31.6182 29.3472 31.2285 29.9767C30.8538 30.5912 30.3367 31.0858 29.6772 31.4606C29.0327 31.8203 28.3132 32.0001 27.5189 32.0001H20.0547V16.0376H27.4964C28.2458 16.0376 28.8903 16.2399 29.4299 16.6446C29.9844 17.0343 30.4041 17.5439 30.6889 18.1734C30.9886 18.788 31.1385 19.4324 31.1385 20.1069C31.1385 20.9013 30.9362 21.6432 30.5315 22.3327C30.1268 23.0072 29.5648 23.5018 28.8453 23.8165C29.7596 24.0863 30.479 24.5959 31.0036 25.3453C31.5432 26.0798 31.813 26.9191 31.813 27.8634ZM30.6664 27.706C30.6664 27.1364 30.5315 26.6043 30.2617 26.1097C30.0069 25.6151 29.6547 25.2104 29.205 24.8957C28.7554 24.5809 28.2458 24.4235 27.6762 24.4235H21.2013V30.9659H27.5189C28.1034 30.9659 28.6355 30.8161 29.1151 30.5163C29.5947 30.2015 29.9694 29.7968 30.2392 29.3022C30.524 28.7926 30.6664 28.2606 30.6664 27.706ZM21.2013 17.0718V23.4568H27.0467C27.6313 23.4568 28.1409 23.3069 28.5755 23.0072C29.0252 22.6924 29.3774 22.2952 29.6322 21.8156C29.887 21.321 30.0144 20.8039 30.0144 20.2643C30.0144 19.6798 29.8945 19.1477 29.6547 18.668C29.4149 18.1884 29.0776 17.8062 28.643 17.5214C28.2233 17.2217 27.7362 17.0718 27.1816 17.0718H21.2013Z" fill="#242731"/>
                    <m.path variants={itemVariants} d="M35.4089 32.0001V16.0376H42.0413C42.7307 16.0376 43.3527 16.18 43.9073 16.4648C44.4769 16.7495 44.964 17.1317 45.3687 17.6114C45.7883 18.076 46.1106 18.6006 46.3354 19.1851C46.5752 19.7697 46.6951 20.3692 46.6951 20.9837C46.6951 21.7781 46.5377 22.52 46.223 23.2095C45.9082 23.899 45.4661 24.476 44.8965 24.9406C44.342 25.4053 43.69 25.6901 42.9406 25.795L46.9199 32.0001H45.5935L41.7265 25.9748H36.5555V32.0001H35.4089ZM36.5555 24.9182H42.1537C42.8431 24.9182 43.4427 24.7308 43.9523 24.3561C44.4619 23.9814 44.8516 23.4943 45.1214 22.8947C45.4061 22.2952 45.5485 21.6582 45.5485 20.9837C45.5485 20.2943 45.3836 19.6573 45.0539 19.0727C44.7391 18.4732 44.312 17.9936 43.7724 17.6338C43.2478 17.2591 42.6558 17.0718 41.9963 17.0718H36.5555V24.9182Z" fill="#242731"/>
                    <m.path variants={itemVariants} d="M55.3598 16.0376H56.349L63.0937 32.0001H61.8797L59.6539 26.7392H52.0324L49.8066 32.0001H48.5701L55.3598 16.0376ZM59.3392 25.795L55.8544 17.4315L52.3022 25.795H59.3392Z" fill="#242731"/>
                    <m.path variants={itemVariants} d="M65.9857 32.0001V16.0376H72.618C73.3075 16.0376 73.9295 16.18 74.4841 16.4648C75.0536 16.7495 75.5407 17.1317 75.9454 17.6114C76.3651 18.076 76.6874 18.6006 76.9122 19.1851C77.152 19.7697 77.2719 20.3692 77.2719 20.9837C77.2719 21.7781 77.1145 22.52 76.7998 23.2095C76.485 23.899 76.0429 24.476 75.4733 24.9406C74.9187 25.4053 74.2667 25.6901 73.5173 25.795L77.4967 32.0001H76.1703L72.3033 25.9748H67.1323V32.0001H65.9857ZM67.1323 24.9182H72.7304C73.4199 24.9182 74.0194 24.7308 74.529 24.3561C75.0386 23.9814 75.4283 23.4943 75.6981 22.8947C75.9829 22.2952 76.1253 21.6582 76.1253 20.9837C76.1253 20.2943 75.9604 19.6573 75.6307 19.0727C75.3159 18.4732 74.8888 17.9936 74.3492 17.6338C73.8246 17.2591 73.2326 17.0718 72.5731 17.0718H67.1323V24.9182Z" fill="#242731"/>

                    <m.path variants={itemVariants} d="M80.0353 16.0376L85.6109 24.8732L91.209 16.0376H92.4905L86.1954 25.9748V32.0001H85.0488V25.9299L78.7762 16.0376H80.0353Z" fill="#242731"/>


                </m.svg>
            </m.div>
        </AnimatePresence>
    );
});
